.App {
  text-align: center;
}

.App-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  z-index: 1;
}

.App-header-desc {
  color: #DDD;
  z-index: 1;
  font-size: calc(10px + 1.2vmin);
}

.cursorBlink {
  opacity: 0;
  animation: swapText 1s infinite alternate;
  font-size: calc(10px + 1.25vmin);
  margin-left: -3px;
}

@keyframes swapText {
  0% {
      opacity: 0;
  }
  75% {
      opacity: 1;
  }
}