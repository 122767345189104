body {
  margin: 0;
  font-family: "IBM Plex Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: "IBM Plex Mono", monospace;
}
